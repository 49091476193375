<template>
  <div id="mian" class="container">
    <!-- <Tabs v-model="curTab">
        <TabPane label="基本信息" name="tab1"></TabPane>
        <TabPane label="银行信息" name="tab2"></TabPane>
        <TabPane label="配置信息" name="tab3"></TabPane>
    </Tabs> -->
    <header class="header">
      <ul class="tab-tilte">
        <li
          :class="{ active: curTab == 'tab1' }"
          @click="handleClickTab('tab1')"
        >
          <span v-auth="'BASIC:FILEMANA:SERVICEINFO/INFO'">基本信息</span>
        </li>
        <li
          :class="{ active: curTab == 'tab2' }"
          @click="handleClickTab('tab2')"
        >
          <span v-auth="'BASIC:FILEMANA:SERVICEINFO/BANKINFO'">银行信息</span>
        </li>
        <li
          :class="{ active: curTab == 'tab3' }"
          @click="handleClickTab('tab3')"
        >
          <span v-auth="'BASIC:FILEMANA:SERVICEINFO/CONFIG'">配置信息</span>
        </li>
      </ul>
    </header>
    <div v-if="curTab === 'tab1' && SERVICEINFO" class="table">
      <div class="desc">
        <h5 class="title float_left">基本信息</h5>
      </div>
      <div class="list clear">
        <ul class="listBox">
          <li>
            <span>服务商全称</span>
            <i class="ellipsis">
              <Tooltip
                class="item"
                effect="light"
                :content="detail.agentName"
                placement="top-start"
              >
                <span>{{ detail.agentName }}</span>
              </Tooltip>
            </i>
          </li>
          <li>
            <span>服务商编号</span>
            <i class="ellipsis">{{ detail.agentId }}</i>
          </li>
          <li>
            <span>联系人</span>
            <i class="ellipsis">{{ detail.contactName }}</i>
          </li>
          <li>
            <span>手机号码</span>
            <i class="ellipsis">{{ detail.contactTel }}</i>
          </li>
          <li>
            <span>邮箱</span>
            <i class="ellipsis">{{ detail.contactEmail }}</i>
          </li>
          <li>
            <span>联系地址</span>
            <i class="ellipsis">
              <Tooltip
                class="item"
                effect="light"
                :content="detail.contactAddress"
                placement="top-start"
              >
                <span class="ellipsis" style="display: block">{{
                  detail.contactAddress
                }}</span>
              </Tooltip>
            </i>
          </li>
          <li>
            <span>法人姓名</span>
            <i class="ellipsis">{{ detail.legalPersonName }}</i>
          </li>
          <li>
            <span>法人身份证号码</span>
            <i class="ellipsis">{{ detail.legalPersonNo }}</i>
          </li>
          <li>
            <span>备注</span>
            <i class="ellipsis">
              <Tooltip
                class="item"
                effect="light"
                :content="detail.remark"
                placement="top-start"
              >
                <span>{{ detail.remark }}</span>
              </Tooltip>
            </i>
          </li>
          <li>
            <span>营业执照</span>
            <i class="ellipsis">
              <a
                v-if="detail.licencePicture"
                @click="showImgModal(detail.licencePicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人身份证人像照片</span>
            <i class="ellipsis">
              <a
                v-if="detail.idcardReversePicture"
                @click="showImgModal(detail.idcardReversePicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人身份证国徽照片</span>
            <i class="ellipsis">
              <a
                v-if="detail.idcardNormalPicture"
                @click="showImgModal(detail.idcardNormalPicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>开户许可证</span>
            <i class="ellipsis">
              <a
                v-if="detail.accountOpeningLicense"
                href="javascript:;"
                @click="showImgModal(detail.accountOpeningLicense)"
              >
                点击预览
              </a>
            </i>
          </li>

          <li>
            <span>服务商协议</span>
            <i class="ellipsis">
              <a v-if="detail.agentProtocol" @click="handleClickAgent(detail)"
                >点击预览</a
              >
            </i>
          </li>

          <li>
            <span>创建时间</span><i class="ellipsis">{{ detail.createTime }}</i>
          </li>
          <li>
            <span>审核时间</span><i class="ellipsis">{{ detail.checkTime }}</i>
          </li>
        </ul>
      </div>
      <div class="status">
        <span class="label">审核状态</span>
        <p>{{ checkStatus[detail.checkStatus] }}</p>
      </div>
      <div class="status">
        <span class="label">服务商状态</span>
        <p>{{ activateStatus[detail.activateStatus] }}</p>
      </div>
    </div>
    <div v-if="curTab === 'tab2'" class="table tab2">
      <div class="desc">
        <h5 class="title float_left">银行信息</h5>
      </div>
      <Table :columns="tableColumns" :data="tableData"></Table>
    </div>
    <div v-if="curTab === 'tab3'" class="table tab3">
      <div class="desc">
        <h5 class="title float_left">资金权限配置</h5>
      </div>
      <Table :columns="authColumns" :data="authData"></Table>
    </div>
    <!-- 查看图片 -->
    <BigImage :show-image.sync="showBigImg" :src="bigImgSrc" />
    <div v-if="showModal" class="cover_view">
      <Carousel
        style="width: 100vw; height: 100vh"
        :autoplay="setting.autoplay"
        :autoplay-speed="setting.autoplaySpeed"
        :dots="setting.dots"
        :radius-dot="setting.radiusDot"
        :trigger="setting.trigger"
        :arrow="setting.arrow"
        @on-click="showModal = false"
      >
        <CarouselItem v-for="(v, i) in imgList" :key="i" class="carouse-item">
          <img :src="v.url" alt="" />
        </CarouselItem>
      </Carousel>
    </div>
  </div>
</template>

<script>
import BigImage from "@/components/BigImage";
import { merchantsInfo } from "@/api/basic/fileManage.js";
const accountType = {
  COMPANY: "企业",
  PERSONAL: "个人",
};

export default {
  components: { BigImage },

  data() {
    return {
      showModal: false,
      curTab: "tab1",
      detail: {},

      tableColumns: [
        { title: "账户名", key: "accountName", align: "center" },
        { title: "账号", key: "accountNo", align: "center" },
        { title: "开户行", key: "bankName", align: "center" },
        { title: "联行号", key: "interBranchesNo", align: "center" },
        {
          title: "账户类型",
          key: "accountType",
          align: "center",
          render(h, { row }) {
            return h("p", `${accountType[row.accountType]}`);
          },
        },
      ],
      tableData: [],

      authColumns: [
        {
          title: "统一处理资金",
          key: "isUniformTreatment",
          render(h, { row }) {
            return h(
              "p",
              `${+row.isUniformTreatment === 1 ? "已开启" : "已关闭"}`
            );
          },
        },
      ],
      authData: [],

      checkStatus: {
        WAIT: "待审核",
        PASS: "已通过",
        REJECT: "已驳回",
      },
      activateStatus: {
        NOTACTIVATE: "未激活",
        ACTIVATE: "已激活",
        FROZEN: "已冻结",
        CLOSED: "已关闭",
      },
      accountType,

      // 查看图片大图
      showBigImg: false,
      bigImgSrc: null,

      imgList: "",
      setting: {
        autoplay: false,
        autoplaySpeed: 2000,
        dots: "inside",
        radiusDot: false,
        trigger: "click",
        arrow: "hover",
      },

      SERVICEINFO: true,
    };
  },
  created() {
    this.initData();
    const _resources = JSON.parse(sessionStorage.getItem("userInfo")).resources;
    if (_resources == "ALL") {
      this.SERVICEINFO = true;
    } else {
      if (_resources.indexOf("BASIC:FILEMANA:SERVICEINFO/INFO") !== -1) {
        this.SERVICEINFO = true;
      } else {
        this.SERVICEINFO = false;
        this.$Message.error("无操作权限，请联系超级管理员开通权限");
      }
    }
  },
  methods: {
    initData() {
      merchantsInfo().then((res) => {
        if (res && res.resultStatus) {
          this.detail = res.resultData.agentDetail || {};
          this.tableData = res.resultData.settlements || [];
          this.authData = [
            {
              isUniformTreatment: res.resultData.agentDetail.isUniformTreatment,
            },
          ];
        }
      });
    },
    // 点击导航
    handleClickTab(tab) {
      this.curTab = tab;
    },
    // 查看图片
    showImgModal(cover) {
      this.showBigImg = true;
      this.bigImgSrc = cover;
    },
    handleClickAgent(detail) {
      this.showModal = true;
      this.imgList = JSON.parse(detail.agentProtocol) || [];
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.header {
  height: 37px;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 24px;
  ul li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .tab-tilte {
    letter-spacing: 0;
    width: 90%;
  }
  .tab-tilte li {
    float: left;
    font-size: 14px;
    color: #515a6e;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    span {
      padding: 0 12px;
    }
  }
  /* 点击对应的标题添加对应的背景颜色 */
  .tab-tilte .active {
    color: #3385ff;
    border-bottom: 2px solid #3385ff;
  }
}
.listBox li i {
  .ellipsis {
    width: 290px;
  }
}

.container {
  overflow: hidden;
  background: #fff;

  .status {
    display: flex;
    padding: 10px 20px;
    background-color: rgba(249, 249, 249, 1);
    margin-bottom: 30px;
    font-size: 15px;
    .label {
      width: 120px;
      font-weight: 500;
      color: var(--label);
    }
  }

  .title {
    color: var(--label);
    padding-left: 0;
    font-size: 15px;
  }

  .table {
    min-height: 800px;
  }
  .tab2 .desc,
  .tab3 .desc {
    margin-bottom: 10px;
  }
  .cover_view {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cover_view img {
    position: absolute;
    min-height: 200px;
    min-width: 200px;
    max-height: 100%;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }
  .cover_view .carouse-item {
    width: 100vw;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}
/deep/ .tab3 .ivu-table-cell {
  text-align: left;
  p {
    margin-left: -5px;
  }
}
/deep/ .ivu-table:before {
  display: none;
}
/deep/ .ivu-table-cell {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
/deep/ .ivu-carousel-track {
  width: 100%;
}
/deep/ .ivu-tooltip-inner {
  max-width: none;
}
</style>
