<template>
  <Modal
    v-model="isShow"
    :width="width"
    :draggable="isDraggable"
    title="查看大图"
    class="img-wrap">
    <div class="img-content">
      <template v-if="isStringType">
        <slot name="size" />
        <img :src="src" alt="大图">
      </template>
      <template v-else>
        <Tabs v-model="activeTab">
          <TabPane
            v-for="(item, index) in src"
            :key="index"
            :label="`封面${index + 1}`"
            :name="`name${index}`">
            <div class="desc">
              <span v-show="item.specs">尺寸：{{ item.specs }}</span>
              <span v-show="item.size">大小：{{ item.size }}</span>
            </div>
            <img :src="item.img" alt="大图">
          </TabPane>
        </Tabs>
      </template>
    </div>
    <div slot="footer" />
  </Modal>
</template>
<script>
export default {
  props: {
    showImage: {
      type: Boolean,
      default: false
    },
    // 'xxxxxxxx.jpg' 或者  [{img: 'xxx.jpg', size: 16KB, specs: 400X600}]
    src: {
      type: [String, Array],
      default: ''
    },
    width: {
      type: String,
      default: '1000'
    },
    isDraggable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeTab: 'name0'
    }
  },
  computed: {
    isShow: {
      get () {
        return this.showImage
      },
      set (val) {
        this.$emit('update:showImage', val)
        if (!val) {
          this.activeTab = 'name0'
        }
      }
    },
    isStringType () {
      return typeof this.src === 'string'
    }
  }
}
</script>
<style lang="less" type="text/css">
  .img-content {
    overflow: hidden;
    height: 95%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .img-content img {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  .img-wrap .ivu-modal-footer {
    border-top: none;
    padding: 0;
  }
  .desc{
    text-align: left;
    margin-bottom: 10px;
    span + span {
      margin-left: 30px;
    }
  }
</style>
<style>
.img-wrap .ivu-modal-wrap {
  display: flex;
  align-items: center;
  margin-top: -25vh;
}
.img-wrap .ivu-modal-wrap .ivu-modal {
  margin-top: 0vh;
  height: 900px;
}
.img-wrap .ivu-modal-wrap .ivu-modal .ivu-modal-body {
  height: 96%;
}
.img-wrap .ivu-modal-wrap .ivu-modal .ivu-modal-content {
  height: 100%;
}
</style>