import axios from '@/utils/request'

export function merchantsInfo(params) {
  return axios({
    url: '/manager/account/agents',
    method: 'get',
    params
  })
}

export function merchantsList(params) {
  return axios({
    url: '/manager/account/merchants',
    method: 'get',
    params
  })
}

// 商户列表-新增商户
export function addShop(data) {
  return axios({
    url: `/manager/account/merchants`,
    method: 'post',
    data
  })
}

export function shopDetail(id) {
  return axios({
    url: `/manager/account/merchants/${id}`,
    method: 'get'
  })
}

// 商户列表-新增分账方
export function mchAllocEdit(mchId, data) {
  return axios({
    url: `/manager/account/mchAlloc/${mchId}`,
    method: 'post',
    data
  })
}

export function setShop(id, data) {
  return axios({
    url: `/manager/account/merchants/${id}`,
    method: 'put',
		data
  })
}

// 审核商户
export function auditShop(id, data) {
  return axios({
    url: `/manager/account/merchants/${id}/check`,
    method: 'put',
    data
  })
}

// 激活/冻结商户
export function setShopStatus(id, data) {
  return axios({
    url: `/manager/account/merchants/${id}/activate`,
    method: 'put',
    data
  })
}

//  商户管理-重发邮件
export function resend(mchId) {
  return axios({
    url: `/manager/account/merchants/${mchId}/email/resend`,
    method: 'post'
  })
}

// 查询可绑定的分账方
export function searAlloc(mchId) {
  return axios({
    url: `/manager/account/merchants/${mchId}/Alloc`,
    method: 'get'
  })
}

// 获取商户列表-详情-渠道信息-电子账簿渠道信息-新增账簿渠道信息/ 编辑（删除）账簿渠道信息，电子账簿信息，入金账户信息
export function postAccountBookChannelAdd(id,data) {
  return axios({
    url: `/manager/account/trade/${data.id}`,
    method: 'post',
    data
  })
}

//  获取商户列表-详情-渠道信息-电子账簿渠道信息-新增账簿渠道信息 -（删除）
export function delAccountBookChannel(mchChannelId,data) {
  return axios({
    url: `/manager/account/trade/${mchChannelId}`,
    method: 'delete',
    data
  })
}

// 分账方列表
export function allocmerchantsLists(params) {
  params.loading = true
  return axios({
    url: `/manager/account/allocmerchants`,
    method: 'get',
		params
  })
}

// 分账方管理-商户详情
export function getAllocmerchantsDetail(allocMchId) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}`,
    method: 'get'
  })
}

// 分账方管理-修改商户详情
export function setAllocmerchants(allocMchId, data) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}`,
    method: 'put',
		data
  })
}

// 分账方管理-新增商户
export function addAllocmerchants(data) {
  return axios({
    url: `/manager/account/allocmerchants`,
    method: 'post',
		data
  })
}

// 分账方管理-编辑结算账户
export function setSettlements(allocMchId, data) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/settlements/${data.settlementId}`,
    method: 'post',
		data
  })
}

// 分账方管理-同步三方账号信息
export function getThirdaccounts(allocMchId, thirdAccountId, params) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/thirdaccounts/${thirdAccountId}/sync`,
    method: 'get',
		params
  })
}

// 分账方管理-签约
export function setThirdaccountsSign(allocMchId, thirdAccountId) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/thirdaccounts/${thirdAccountId}/sign`,
    method: 'post'
  })
}

// 分账方管理-查询签约内容
export function setThirdaccountsSignC(allocMchId, thirdAccountId) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/thirdaccounts/${thirdAccountId}/signcontent`,
    method: 'get'
  })
}

//分账方管理-分账方信息-商户签约-获取签约链接
export function setThirdaccountsSignUrl(allocMchId, thirdAccountId) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/thirdaccounts/${thirdAccountId}/signUrl`,
    method: 'post'
  })
}

// 分账方管理-上传图片
export function setImage(allocMchId, data) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/picture/upload`,
    method: 'post',
    data
  })
}

//补充材料-编辑 
export function putsupplementEidt(mchId,data) {
	return axios({
		url: `/manager/account/merchants/supplement/${mchId}`,
		method: 'put',
		data
	})
}